import { Routes } from '@angular/router';

export const CommonLayout_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    // {
    //     path: 'home/:id/:labid',
    //     loadChildren: () => import('../../home/home.module').then(m => m.HomeModule),
    // },
    {
        path: 'home',
        loadChildren: () => import('../../home/home.module').then(m => m.HomeModule),
    },
    {
        path: 'patientprofile/:id',
        loadChildren: () => import('../../patientprofile/patientProfile.module').then(m => m.PatientProfileModule),
    },
    {
        path: 'nopatientfound',
        loadChildren: () => import('../../noPatientFound/noPatientFound.module').then(m => m.NoPatientFoundModule),
    },
    {
        path: 'currentlist',
        loadChildren: () => import('../../currentlist/currentlist.module').then(m => m.CurrentlistModule),
    }
    ,
    {
        path: 'labprofile',
        loadChildren: () => import('../../labProfile/labProfile.module').then(m => m.LabProfileModule),
    }
];
