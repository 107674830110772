<div class="header">
    <div class="nav-wrap">
        <div class="logo logo-white">
            <a href="">
                <img src="assets/images/logo/logowhite.svg" alt="Logo">
            </a>
        </div>
        <ul class="nav-left">
            <li [routerLink]="['/home']" routerLinkActive="active">
                <a><i nz-icon nzType="search"></i> البحث عن عميل</a>
            </li>

        </ul>
        <ul class="nav-right">
            <li [routerLink]="['/labprofile']" routerLinkActive="active">
                <a>إعدادات المعمل</a>
            </li>
            <!-- <li>
                <a href="">Settings</a>
            </li> -->
        </ul>
    </div>
</div>    
