// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    /* Quality */
    // mainWebAppURL: 'https://web.htag.health/',
    //  API_URL2: 'https://gateway-ecs.htag.health/api/',
    // API_URL: 'https://gateway-ecs.htag.health/services/webappmicroservice-dev/api/',
    //  mobileService: 'https://gateway-ecs.htag.health/services/mobilemicroservice-dev/api/'

    /* Production */
    mainWebAppURL: 'https://htag.health',
    API_URL: 'https://gateway-prod.htagdev.info/services/webappmicroservice-prod/api/',
    API_URL2: 'https://gateway-prod.htagdev.info/api/',
    mobileService: 'https://gateway-prod.htagdev.info/services/mobilemicroservice-prod/api/'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
