import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NzModalService} from 'ng-zorro-antd/modal';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private router: Router, private modalService: NzModalService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const unOuth = [
            environment.API_URL2 + 'authenticate',
            environment.API_URL2 + 'governorates'
        ];
        const currentUserToken = JSON.parse(localStorage.getItem('token'));
        if (!unOuth.includes(request.url)) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + currentUserToken
                }
            });
        }

        return next.handle(request).pipe(tap(() => {
            },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status !== 401) {
                        return;
                    }
                    debugger
                    localStorage.clear();
                    this.router.navigate(['/auth']);
                    this.modalService.closeAll();
                    return;
                }
            }));
    }
}
