import {NgModule} from '@angular/core';
import {RouterModule, Routes, PreloadAllModules} from '@angular/router';

import {FullLayoutComponent} from './layouts/full-layout/full-layout.component';
import {CommonLayoutComponent} from './layouts/common-layout/common-layout.component';

import {FullLayout_ROUTES} from './shared/routes/full-layout.routes';
import {CommonLayout_ROUTES} from './shared/routes/common-layout.routes';
import {SessionCheckGuard} from './shared/guards/session-check.guard';
import {AuthGuard} from './shared/guards/Autherization-guard/auth.guard';
import {UnAuthGuard} from './shared/guards/Autherization-guard/un-auth.guard';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/auth',
        pathMatch: 'full',
    },
    {
        path: '',
        component: CommonLayoutComponent,
        children: CommonLayout_ROUTES,
        canActivate: [AuthGuard]
    },
    {
        path: '',
        component: FullLayoutComponent,
        children: FullLayout_ROUTES,
        canActivate: [UnAuthGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}