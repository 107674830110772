import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent {

    constructor(private activatedRoute: ActivatedRoute) {
        // localStorage.setItem('token', "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIwMTMxNjQyMzk3OSIsImF1dGgiOiJST0xFX1VTRVIiLCJleHAiOjE2Nzk2MTI3NDF9.SKWhQmVBlVniOmbe-nTIgESnZ6SAdm8AV2Mvr3aQfMpaBzipduEaOPrr7_hnz-nXBNSH9o93xRZxfCzGhfXzEQ");
        // localStorage.setItem('labId', '1006');

    }
}
